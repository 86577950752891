import React from 'react';
import { Container, Grid, Typography } from '@mui/material';
import packageJson from '../../package.json';

const SuperiorFooter = () => {
  // adding version number to footer
  const versionNumber = process.env.REACT_APP_VERSION || '1.0.0';

  //grab versioning number from package.json
  const packageVersioning = packageJson.version;

  return (
    <Container maxWidth="xl">
      <Grid container bgcolor="primary" alignItems="center">
        <Grid item xs={12} textAlign="center" pt={3} pb={3}>
          <Typography variant="caption" color="white" gutterBottom>
            Superior IRA &amp; HSA<sup>&reg;</sup> and the Superior IRA &amp;
            HSA logo are registered trademarks of Superior IRA &amp; HSA, LLC.
          </Typography>
          <Typography variant="body2" color="white">
            &copy; {new Date().getFullYear()} Superior IRA &amp; HSA, LLC. All
            Rights Reserved. Version: {packageVersioning} 
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SuperiorFooter;
